module Main exposing (main)

import Browser
import Browser.Navigation as Navigation
import Html.Extra
import Page
import Pages.About
import Pages.Contact
import Pages.DataProtection
import Pages.Home
import Pages.Imprint
import Pages.References
import Pages.Services
import Route
import Shared
import Url
import Url.Parser


type alias Model =
    { key : Navigation.Key
    , route : Route.Route
    , shared : Shared.Shared
    }


type Msg
    = UrlChanged Url.Url
    | LinkClicked Browser.UrlRequest
    | Shared Shared.Msg


init : () -> Url.Url -> Navigation.Key -> ( Model, Cmd Msg )
init _ url key =
    ( { key = key
      , route = parseRoute url
      , shared = Shared.init
      }
    , Cmd.none
    )


parseRoute : Url.Url -> Route.Route
parseRoute url =
    case Url.Parser.parse Route.parser url of
        Nothing ->
            Route.NotFound

        Just route ->
            route


handleRoute : Url.Url -> Model -> ( Model, Cmd Msg )
handleRoute url model =
    case parseRoute url of
        Route.References (Just id) ->
            ( { model | route = Route.References (Just id) }, Html.Extra.scrollTo id )

        route ->
            ( { model | route = route }, Html.Extra.scrollTop () )


update : Msg -> Model -> ( Model, Cmd Msg )
update msg model =
    case msg of
        Shared sharedMsg ->
            ( { model | shared = Shared.update sharedMsg model.shared }, Cmd.none )

        UrlChanged url ->
            handleRoute url model

        LinkClicked urlRequest ->
            case urlRequest of
                Browser.Internal url ->
                    ( model, Navigation.pushUrl model.key (Url.toString url) )

                Browser.External href ->
                    ( model, Navigation.load href )


view : Model -> Browser.Document Msg
view { route, shared } =
    case route of
        Route.Home ->
            Page.view Shared shared route Pages.Home.view

        Route.Contact ->
            Page.view Shared shared route Pages.Contact.view

        Route.References _ ->
            Page.view Shared shared route Pages.References.view

        Route.Imprint ->
            Page.view Shared shared route Pages.Imprint.view

        Route.Services ->
            Page.view Shared shared route Pages.Services.view

        Route.About ->
            Page.view Shared shared route Pages.About.view

        Route.DataProtection ->
            Page.view Shared shared route Pages.DataProtection.view

        Route.NotFound ->
            Page.view Shared shared route Pages.Home.view


subscriptions : Model -> Sub Msg
subscriptions _ =
    Html.Extra.onScroll (\i -> Shared (Shared.Scroll i))


main : Program () Model Msg
main =
    Browser.application
        { init = init
        , view = view
        , update = update
        , subscriptions = subscriptions
        , onUrlRequest = LinkClicked
        , onUrlChange = UrlChanged
        }
