import { Elm } from './Main.elm'
const app = Elm.Main.init({ node: document.querySelector('body') })

window.addEventListener('scroll', function(event) {
    app.ports.onScroll.send(window.scrollY)
})

// Port to scroll to a element by id, scroll to top first
app.ports.scrollTo.subscribe(function(id) {
    window.scrollTo(0, 0)
    setTimeout(() => {
        document.getElementById(id).scrollIntoView({ behavior: 'smooth' })
    }, 100)
})

// Port to scroll to top of the view
app.ports.scrollTop.subscribe(function() {
    setTimeout(() => {
        window.scrollTo(0, 0)
    }, 100)
})


if (process.env.NODE_ENV === 'production') {
    console.log("runs")
    // Only runs in development and will be stripped in production builds.
    window.dataLayer = window.dataLayer || [];
    function gtag() { dataLayer.push(arguments); }
    gtag('js', new Date());
    gtag('config', 'G-97TDBLFN6R');
}
